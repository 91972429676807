<template>
  <div class="login-form" :class="'login-form--' + theme">
    <h1 class="login-form__title">{{ $t("loginForm.title") }}</h1>
    <form
      class="login-form__form"
      ref="loginForm"
      :model="form"
      @submit.prevent=""
      novalidate
    >
      <div>
        <div
          class="inputError-main"
          v-if="validationErrors?.message"
          :key="index"
        >
          {{ validationErrors?.message }}
        </div>
        <Input
          class="login-form__input"
          type="text"
          theme="login"
          id="email"
          :label="$t('loginForm.inputUsername')"
          v-model.trim="v$.form.username.$model"
        />
      </div>
      <div>
        <Input
          class="login-form__input"
          type="password"
          theme="login"
          id="pasw"
          :label="$t('loginForm.inputPassword')"
          v-model.trim="v$.form.password.$model"
        />
        <span
          v-for="(error, index) of v$.form.password.$errors"
          :key="index"
          class="inputError"
        >{{ error.$message }}</span
        >

        <span
          v-for="(error, index) of v$.form.password.$errors"
          :key="index"
          class="inputError"
        >{{ error.$message }}</span
        >

        <span class="inputError" v-if="failedLogin">
          {{ $t("loginForm.errorLogin") }}</span
        >
      </div>

      <div class="login-form__remember">
        <div class="checkbox-field">
          <input
              type="checkbox"
              id="rememberMe"
              class="checkbox-field__input"
              v-model="form.checked"
          />
          <label class="checkbox-field__label" for="rememberMe">{{
              $t("loginForm.inputRememberMe")
            }}</label>
        </div>
        <a target="_blank" v-bind:href="portalLink + '/ForgotPass'">{{
            $t("loginForm.btnNeedHelp")
          }}</a>
      </div>

      <div class="button-container">
        <Button
          theme="red"
          style="margin-bottom: 10px;"
          :label="$t('loginForm.btnSignIn')"
          @click="handleSubmit"
        />

        <div class="button-container__row">
          <div>
            {{$t('loginForm.registerLabel')}}
            <Button
                theme="link"
                :label="$t('loginForm.btnRegister')"
                @click="onRegisterNow"
            />
          </div>

          <Button
              theme="link"
              :link="true"
              :to="portalLink"
              class="open-portal-btn"
              :label="$t('loginForm.myAccount')"
          />
        </div>
      </div>

    </form>
    <RegisterFree :click="closeRegistration" v-show="showRegistrationPage" />
    <RegisterMobilePopup :click="closeMobileAppPopup" v-show="showMobileAppPopup"/>
  </div>
</template>

<script>
import Input from "@/components/Input";
import Button from "@/components/Button";
import { Base64 } from "js-base64";
import useVuelidate from "@vuelidate/core";
import RegisterFree from "@/components/RegisterFree";
import { getItem } from "@/helpers/storage";
import {
  required,
  email,
  minLength,
  maxLength,
  helpers
} from "@vuelidate/validators";
import { mapState } from "vuex";
import RegisterMobilePopup from "@/components/RegisterMobilePopup.vue";
import {isAndroid, isIOS} from "@/helpers/utils";

export default {
  name: "LoginForm",
  data() {
    return {
      v$: useVuelidate(),
      showRegistrationPage: false,
      form: {
        username: this.usr,
        password: this.psd,
        checked: true,
        usr: "",
        psd: ""
      },
      errorLoginValid: (state) => {
        console.log(state.auth);
        return state.auth.failedLogin;
      },
      showMobileAppPopup: false,
      isMobile: isAndroid() || isIOS(),
    };
  },
  computed: {
    ...mapState({
      failedLogin: (state) => state.auth.failedLogin,
      portalLink: (state) => state.config?.portalLink,
      theme: (state) => {
        return state.config?.brandName || "";
      },
    })
  },
  validations() {
    return {
      form: {
        username: {
          required: helpers.withMessage(
            "Please enter a valid username.",
            required
          ),
          email: helpers.withMessage(
            "Please enter a valid email address",
            email
          )
        },
        password: {
          required: helpers.withMessage(
            "Your password must contain between 4 and 60 characters.",
            required
          ),
          min: minLength(4),
          max: maxLength(60)
        }
      }
    };
  },
  components: {
    RegisterMobilePopup,
    Input,
    RegisterFree,
    Button
  },
  methods: {
    handleSubmit() {
      this.v$.$validate();
      this.$refs["loginForm"] = this.$emit("on-success-valid", {
        username: this.form.username,
        password: this.form.password
      });
      const token = getItem("accessToken");
      if (token) {
        if (this.form.checked) {
          this.setCookie("username", this.form.username, 30);
          this.psd = Base64.encode(this.form.password);
          this.setCookie("psd", this.psd, 30);
        } else {
          this.setCookie("username", "", 0);
          this.setCookie("psd", "", 0);
        }
      }
    },
    initForm() {
      this.usr = this.getCookie("username");
      this.psd = this.getCookie("psd");
      if (this.usr && this.psd) {
        this.form.username = this.usr;
        this.form.password = this.psd;
      }
    },
    setCookie(cName, value, expireDays) {
      let exDate = new Date();
      exDate = exDate.setDate(exDate.getDate() + expireDays);
      document.cookie =
        cName +
        "=" +
        value +
        (expireDays === undefined
          ? ""
          : ";expires=" + new Date(exDate).toUTCString());
    },
    closeRegistration(e) {
      if (!e?.data || e?.data?.action === "close") {
        this.showRegistrationPage = false;
        if (this.isMobile) this.showMobileAppPopup = true;
      }
    },
    closeMobileAppPopup() {
      this.showMobileAppPopup = false;
    },
    onRegisterNow() {
      this.showRegistrationPage = true;
    },
    getCookie(key) {
      if (document.cookie.length > 0) {
        let start = document.cookie.indexOf(key + "=");
        if (start !== -1) {
          start = start + key.length + 1;
          let end = document.cookie.indexOf(";", start);

          if (end === -1) {
            end = document.cookie.length;
            return Base64.decode(document.cookie.substring(start, end));
          } else {
            return document.cookie.substring(start, end);
          }
        }
      }
      return "";
    }
  },
  mounted() {
    this.initForm();
  }
};
</script>

<style lang="scss" scoped>
.login-form {
  flex-grow: 1;

  &__title {
    color: $white;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 28px;
  }

  &__input {
    padding-bottom: 16px;
  }

  &__button {
    margin: 16px 0 0;
    width: 100%;
  }

  &__remember-link {
    color: $light-gray;
    font-size: 13px;
    font-weight: 500;
  }

  &__remember {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__signup {
    margin: 16px 0;
    display: flex;
    align-items: center;
    // margin-bottom: 150px;
    .btn {
      color: #fff;
      // font-size: 18px;
      // border: solid 2px $free-signup;
      padding: 10px;
      width: 100%;
    }
  }

  a {
    font-size: 12px;
    color: $bg-red;
  }
  .button-container {
    display: flex;
    gap: 5px;
    flex-direction: column;
    margin-top: 35px;

    button {
      color: $white;
      background-color: $bg-red;
      font-size: 16px;
    }
    .btn--link {
      background-color: transparent;
      color: $bg-red;
    }
    .btn--link, .link {
      font-size: 12px;
      font-weight: normal;
    }

    &__row {
      display: flex;
      flex-flow: row;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }

  &--apollogroup {
    .button-container {
      .btn--red {
        color: white;
        background-color: $blue;
      }
      .btn--link {
        background-color: transparent;
        color: $blue;
      }
    }
    a {
      color: $blue;
    }
  }

  &--pizzatime {
    .button-container {
      .btn--red {
        color: white;
        background-color: $orange;
      }
      .btn--link {
        background-color: transparent;
        color: $orange;
      }
    }
    a {
      color: $orange;
    }
  }
}

.inputError {
  color: $error;
  font-size: 13px;
  position: relative;
  padding: 6px 3px;
  margin-top: -12px;
  margin-bottom: 10px;
  display: flex;

  &-main {
    padding: 0 3px 10px;
    color: $red10;
    font-size: 14px;
  }
}

.checkbox-field {
  display: flex;
  align-items: center;

  &__label {
    color: #b3b3b3;
    display: block;
    line-height: 1.2;
    margin-left: 5px;
    font-size: 13px;

    &--large {
      color: #333;
      display: block;
      line-height: 1.2;
      padding: 6px 0;
      position: relative;
      font-size: 16px;
      min-height: 20px;
    }
  }

  &__input {
    background-color: $dark-gray;
    border: 0;
    border-radius: 2px;
    height: 16px;
    left: -20px;
    width: 16px;

    &--large {
      border-radius: 50%;
      height: 25px;
      width: 25px;
    }
  }
}
</style>
